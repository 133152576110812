// Your JSX component (Footer.js)
import React, { useState, useEffect, useRef } from 'react';
import '../assets/css/Footer.css';
import inv from '../assets/abLogoInv.png';
import { useNavigate } from 'react-router-dom';
import { IoLogoLinkedin } from "react-icons/io5";
import Contact from './Contact';

const Footer = () => {

  const navigate = useNavigate();

  const articlesNav = () => {
    navigate('/articles/all');
    window.scrollTo(0, 0);
  };

  const homeNav = () => {
    navigate('/home');
    window.scrollTo(0, 0);
  };

  const specialitiesNav = () => {
    navigate("/specialities");
    window.scrollTo(0, 0);
  };

  const contactNav = () => {
    const contactSection = document.querySelector('.footer');
    
    if (contactSection) {
        // Smooth scrolling with slower animation
        contactSection.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    } else {
        console.error('Contact section not found');
    }
};

  const ref = useRef(null);

  return (
    <div className="footer">
      <div className="contact-section">
        <h1 className="contact-us">Nous Contacter</h1>
        <Contact/>
      </div>
      <div className='bottom-section'>
        <div className='logo-footer'>
            <img src={inv} alt="Logo"></img>
          </div>
        <div className="links-section">
          <div className="column-1">
            <div className='footer-link' style={{cursor:'pointer'}} onClick={homeNav}>Accueil</div>
            <div className="footer-link" style={{cursor:'pointer'}} onClick={specialitiesNav}>Compétences</div>
            <div className='footer-link' style={{paddingTop:'5vh'}}>Email</div>
            <div className='normal-text'>contact@bharandassociates.com</div>
          </div>
          <div className='column-2'>
            <div className='footer-link' style={{cursor:'pointer'}} onClick={articlesNav}>Blog</div>
            <div className="footer-link" style={{cursor:'pointer'}} onClick={contactNav}>Contact</div>
            <div className='footer-link' style={{paddingTop:'5vh'}}>Téléphone</div>
            <div className='normal-text'>+216 24 800 393</div>
          </div>
          <div className='column-3'>
            <div className='footer-link'>Le Cabinet</div>
            <div className="normal-text">6 Rue D'Alger, Tunis, 1000</div>
            <div className='footer-link' style={{paddingTop:'5vh'}}>Nous Suivre</div>
            <div className='white-icon'>
              <a href='https://www.linkedin.com/company/bhar-avocats/'>
                <IoLogoLinkedin />
              </a>
            </div>
          </div>
        </div>
        <div className='sepr'></div>
      </div>
    </div>
  );
}

export default Footer;
