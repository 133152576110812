import React, { useState, useEffect } from 'react';
import './assets/css/Articles.css';
import articlesData from './articleData.json';
import allArticles from './assets/articlesAll.png';
import { useParams, useNavigate } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Categories from './components/Categories';

const Articles = () => {
    const navigating = useNavigate();
    const { category } = useParams();
    const [data, setData] = useState([]);
    const [filteredArticles, setFilteredArticles] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setData(articlesData);
        setFilteredArticles(articlesData);
    }, []);

    useEffect(() => {
        if (category) {
            if (category === 'all') {
                showAllArticles();
            } else {
                filterByCategory(category);
            }
        }
    }, [category, data]);

    const filterByCategory = (category) => {
        const filtered = category
            ? data.filter(article => article.category.toUpperCase() === category.toUpperCase())
            : data;

        setFilteredArticles(filtered);
        setSelectedCategory(category);
    };

    const showAllArticles = () => {
        setFilteredArticles(data);
        setSelectedCategory(null);
    };

    const navigateToArticle = (article) => {
        navigating(`/article/${article.title}`);
    };

    const articlesPerPage = 15; // Number of articles per page

    const indexOfLastArticle = currentPage * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <div className='articlesPage'>
            <div className="article-banner" onClick={showAllArticles}>
                <p>TOUS LES ARTICLES</p>
            </div>
            <Categories
                leftButtonImage={allArticles}
                currentPage="articles"
                filterArticlesByCategory={filterByCategory}
            />
            <div className="articlesAndPagination">
                <div className='articles'>
                    {currentArticles
                        .sort((a, b) => a.title.localeCompare(b.title)) // Sort articles alphabetically
                        .map((article, index) => (
                            <div key={index} className="blog-articles-container">
                                <div className='article-image'>
                                    <a href={`/article/${article.title}`}>
                                        <img src={article.imags} alt={article.title} />
                                    </a>
                                </div>
                                <div className='info'>
                                    <a href={`/article/${article.title}`}>
                                        <span>{article.dateCreated}</span>
                                    </a>
                                    <a href={`/article/${article.title}`}>
                                        <h2>{article.title}</h2>
                                    </a>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
            <Stack spacing={2}>
                <Pagination
                    style={{ margin: "5% auto", color: '#001F54' }}
                    count={Math.ceil(filteredArticles.length / articlesPerPage)}
                    page={currentPage}
                    onChange={handleChange}
                    size="large"
                />
            </Stack>
        </div>
    );
};

export default Articles;
