import './assets/css/Article.css';
import React, { useState, useEffect } from 'react';
import Sommaire from "./components/Sommaire";
import ArticleContent from './components/ArticleContent';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import Banner from './components/Banner';
import articlesData from './articleData.json';

const Article = ( articlesWidth ) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [topArticles, setTopArticles] = useState([]);


    useEffect(() => {
        setTopArticles(articlesData.filter(article => article.isTopArticle).slice(0, 4));
    }, []);


      const navigate = useNavigate()


      const articlesNav = () => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
        // Then navigate to '/articles/top'
        navigate('/articles/top');
      };

      const downloadPdf = () => {
        // Create a new jsPDF instance
        const doc = new jsPDF();
        // Set margins
        const margin = 20;
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        // Set the initial position for rendering
        let startY = margin;
        // Define the content of the PDF
        const title = `${article.title}`;
        const authorAndDate = `Rédacteur(s): ${article.author}\nCréé le: ${new Date(article.dateCreated).toLocaleDateString()}`;
        const subtitles = article.subtitles;
        // Calculate the width and height of the title
        const titleWidth = pageWidth - 2 * margin;
        // Add title to the PDF
        doc.setFontSize(25);
        doc.setFont('helvetica', 'bold');
        doc.setTextColor('#001F54');
        const splitTitle = doc.splitTextToSize(title, titleWidth);
        splitTitle.forEach(line => {
            if (startY + 10 > pageHeight - margin) {
                doc.addPage();
                startY = margin;
            }
            doc.text(line, margin, startY, { maxWidth: titleWidth, align: 'justify' });
            startY += 10;
        });
        startY += 10;
        // Add author and creation date
        doc.setFontSize(20);
        doc.setFont('helvetica', 'bold');
        doc.setTextColor('#001F54');
        if (startY + 20 > pageHeight - margin) {
            doc.addPage();
            startY = margin;
        }
        doc.text(authorAndDate, margin, startY);
        startY += 20;
        startY += 10;
        // Reset the font style to normal
        doc.setFont('helvetica', 'normal');
        // Add subtitles and content as paragraphs
        subtitles.forEach(subtitle => {
            if (subtitle.title) {
                doc.setFontSize(20);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor('#001F54');
                const splitSubtitle = doc.splitTextToSize(subtitle.title, titleWidth);
                splitSubtitle.forEach(line => {
                    if (startY + 10 > pageHeight - margin) {
                        doc.addPage();
                        startY = margin;
                    }
                    doc.text(line, margin, startY, { maxWidth: titleWidth, align: 'justify' });
                    startY += 10;
                });
                startY += 10;
                const underlineY = startY - 10;
                doc.setDrawColor('#001F54');
                doc.setLineWidth(0.5);
                doc.line(margin, underlineY, margin + doc.getStringUnitWidth(subtitle.title) * 1.2, underlineY);
                startY += 20;
            }
            if (subtitle.content) {
                doc.setFontSize(15);
                doc.setFont('helvetica', 'normal');
                doc.setTextColor(0);
                const lines = doc.splitTextToSize(subtitle.content, pageWidth - 2 * margin);
                lines.forEach(line => {
                    if (startY + 10 > pageHeight - margin) {
                        doc.addPage();
                        startY = margin;
                    }
                    doc.text(line, margin, startY, { maxWidth: pageWidth - 2 * margin });
                    startY += 10;
                });
                startY += 5; // Add space after content
            }
            if (subtitle.table) {
                doc.autoTable({
                    startY: startY, // Add space before the table
                    head: [subtitle.table.headers],
                    body: subtitle.table.rows
                });
                startY = doc.lastAutoTable.finalY;
                startY += 10; // Add space after the table
            }
        });
        doc.save(`${article.title}.pdf`);
    };
    
    
    const [article, setArticle] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Assuming you want a loading state

    const [modalOpen, setModalOpen] = useState(false);
    const close = () => setModalOpen(false);
    const [emailSubmitted, setEmailSubmitted] = useState(localStorage.getItem("emailSubmitted"));
    console.log(localStorage.getItem("emailSubmitted"))

    // Function to handle download or open modal based on the email submission status
    const handleDownloadOrModal = () => {

        downloadPdf();

    };

    const { title } = useParams();

    useEffect(() => {
        // Find the article with the matching title in articlesData
        const selectedArticle = articlesData.find(article => article.title === title);

        if (selectedArticle) {
            setArticle(selectedArticle);
        } else {
            console.error('Article not found');
        }

        setIsLoading(false);
    }, [title]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!article) {
        return <div>Article not found</div>;
    }
    return (
        <div className="article-container">
            <Banner title={article.title} image={article.imags}></Banner>
            <div className="articleContent">
                <div className='article-text'>
                    {/* Display the first section of the article */}
                    <ArticleContent article={article} firstSectionOnly noSubtitles />
                </div>
                    {/* Display the sommaire */}
                    <Sommaire subtitles={article.subtitles} />
                <div className='article-text'>
                    {/* Display the rest of the article */}
                    <ArticleContent article={article} startFromSecondSection />
                </div>
            </div>
            <hr className="article-sepr"></hr>
            <div className="content">
                <div className="category">
                    <div className='category-info'>
                        <span className="text-sub">Mise en ligne: </span>
                        <span style={{fontWeight:'500'}}>{new Date(article.dateCreated).toLocaleDateString()}</span>
                    </div>
                    <div className='category-info'>
                        <span className="text-sub">Rédacteurs: </span>
                        <span style={{fontWeight:'500'}}>{article.author}</span>
                    </div>
                </div>
                <div className='category' onClick={handleDownloadOrModal}>
                    <span className="article-download">Télécharger L'Article</span>
                </div>
            </div>
            <div className='article-discovery'>
                <h1 style={{textTransform: 'uppercase'}}>découvrez nos articles</h1>
                <div className='sepr'></div>
                <div className='topArticles'>
                    {topArticles.map((article, index) => (
                            <div key={index} className="solo-articles-container" style={{ width: `${articlesWidth}` }}>
                            <a href={`/article/${article.title}`}>
                                <img src={article.imags} alt={article.title} />
                            </a>
                            <div className='info'>
                                <span style={{ color: "#6981CF", textAlign:"left", textTransform:"uppercase" }}> {article.dateCreated}</span>
                            </div>
                            <a href={`/article/${article.title}`}>
                                <h2 style={{ marginTop: "5%" }} >{article.title}</h2>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div >
    );
}

export default Article;
