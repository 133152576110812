// Banner.js
import React from 'react';

const Banner = ({ image, title }) => {
  return (
<div className="banner" style={{ 
    height: '50vh',
    width:'100%',
    backgroundPosition:'center',
    boxSizing:'border-box',
    backgroundSize:'cover',
    backgroundImage: `linear-gradient(to bottom, rgba(232, 238, 253,0), rgba(232, 238, 253,0.8) 90%), url(${image})`
}}>      
      <div className="overlay">
        <h1 style={{color:'white', textAlign:'center', textTransform:'uppercase' }}>{title}</h1>
      </div>
    </div>
  );
};

export default Banner;
