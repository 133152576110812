import React from 'react';
import '../assets/css/Article.css';

const ArticleContent = ({ article, firstSectionOnly, startFromSecondSection, noSubtitles }) => {
  let sectionsToDisplay = article.subtitles;

  if (firstSectionOnly) {
    // Display only the title of the article as the first section
    sectionsToDisplay = [{ content: article.subtitles[0].content }];
  } else if (startFromSecondSection) {
    // Display the rest of the sections (excluding the first section)
    sectionsToDisplay = article.subtitles.slice(1);
  }

  return (
    <div className="article">
      {sectionsToDisplay.map((section, index) => (
        <section key={index} id={`subtitle${section.title}`}>
          {noSubtitles ? null : <h1 style={{fontWeight:'900'}}>{section.title}</h1>}
          {/* Check if content contains table data */}
          {section.table ? (
            <React.Fragment>
              {/* Display content before the table */}
              {section.content && <p style={{ whiteSpace: "pre-line" }}>{section.content}</p>}
              {/* Display the table */}
              <table>
                <thead>
                  <tr>
                    {section.table.headers.map((header, idx) => (
                      <th key={idx}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {section.table.rows.map((row, idx) => (
                    <tr key={idx}>
                      {row.map((cell, cellIdx) => (
                        <td key={cellIdx}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </React.Fragment>
          ) : (
            <p style={{ whiteSpace: "pre-line" }}>{section.content}</p>
          )}
        </section>
      ))}
    </div>
  );
};

export default ArticleContent;
