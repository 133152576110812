import React from 'react';
import './assets/css/Specialities.css';

/* Big card imports */
import societeBig from './assets/societes-blue.png'
import travailBig from './assets/travail-white.png'
import penalBig from './assets/penal-white.png'
import piBig from './assets/pi-blue.png'
import itBig from './assets/it-blue.png'
import commerceBig from './assets/commercial-white.png'
import immoBig from './assets/immobilier-white.png'
import fiscalBig from './assets/fiscal-blue.png'

import comp from './assets/comp.png'

import Categories from './components/Categories';

  const Card = ({ title, description, imageBig, index }) => {
    const patternColors = ['#B0BFF066', '#3262b499', '#001F54CC', 'white'];
    const textPatternColors = ['#001F54', 'white', 'white', '#001F54', '#001F54', 'white', 'white', '#001F54'];
    const textColor = textPatternColors[index % patternColors.length];
    const backgroundColor = patternColors[index % patternColors.length];

    return (
      <div className="card" style={{ background: backgroundColor}}>
        <div className="card-icon">
          <img src={imageBig} alt={title} />
        </div>
        <div className="card-content">
          <h3 style={{ color: textColor, whiteSpace: 'pre-line'}}>{title}</h3>
          <p style={{ color: textColor, whiteSpace: 'pre-line' }}>{description}</p>
        </div>
      </div>
    );
  };
const Specialities = () => {

  const scrollToCard = (realTitle) => {
    const cardElement = document.getElementById(realTitle);
    if (cardElement) {
      const scrollOptions = {
        behavior: 'smooth',
        block: 'start',
      };

      // Calculate the offset based on the height of the title bar
      const titleBarHeight = 50; // Adjust this value based on your actual title bar height
      const offset = -titleBarHeight;

      // Calculate the scroll distance and adjust for the title bar
      const scrollDistance = cardElement.getBoundingClientRect().top + window.scrollY;
      const targetTop = scrollDistance + offset;

      let isInterrupted = false;

      // Handle user input to interrupt the animation
      window.addEventListener('wheel', () => {
        isInterrupted = true;
      });

      // Perform smooth scroll with requestAnimationFrame
      const animateScroll = (startTime) => {
        if (isInterrupted) {
          return;
        }

        const currentTime = Date.now();
        const progress = Math.min(1, (currentTime - startTime) / 10000); // Duration: 1000ms (1 second)
        const easeInOutQuad = t => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t; // Easing function

        const newScrollY = window.scrollY + easeInOutQuad(progress) * (targetTop - window.scrollY);

        window.scrollTo({
          top: newScrollY,
          behavior: 'auto', // Use 'auto' to allow custom scrolling behavior
        });

        if (Math.abs(newScrollY - targetTop) > 1) { // Check if close enough to the target
          requestAnimationFrame(() => animateScroll(startTime));
        } else {
          window.scrollTo({
            top: targetTop,
            behavior: 'smooth',
          });
        }
      };

      // Start the smooth scroll animation
      animateScroll(Date.now());
    }
  };

  const cards = [
    {
      title: 'DROIT DES SOCIÉTÉS',
      realTitle:'DROIT DES SOCIETES',
      imageBig: societeBig,
      description: "Le cabinet propose une offre juridique globale tout au long de la vie de l’entreprise en conseil et en contentieux notamment:\n\n- Création et conseil sur la structuration de ses sociétés.\n- Rédaction des documents contractuels.\n- Opérations sur capital.\n- Secrétariat juridique.\n- Responsabilité des organes de direction.\n- Litiges entre actionnaires.\n- Restructuration et liquidation."
    },
    {
      title: 'DROIT DU TRAVAIL',
      realTitle:'DROIT DU TRAVAIL',
      imageBig: travailBig,
      description: "La sécurisation économique de l’entreprise passe par un accompagnement juridique constant en matière de droit du travail, les avocats du cabinet offrent leur expertise dans tous les domaines du droit social notamment:\n\n- Négociation et rédaction des contrats de travail et clauses spécifiques.\n- Rédaction et mise en place des règlements intérieurs et codes de conduites.\n- Assistance à la résolution amiable des conflits individuels et collectifs de travail.\n- Négociation et rédaction des protocoles d’accord transactionnel.\n- Représentation devant le conseil de prud’hommes."
    },
    {
      title: 'DROIT PÉNAL DES AFFAIRES',
      realTitle:'DROIT PENAL DES AFFAIRES',
      imageBig: penalBig,
      description: "La connaissance du fonctionnement des entreprises ainsi que l’expérience acquise en droit pénal valent à nos avocats une qualification particulière pour:\n\n-Assurer la défense des intérêts de toute partie prenante dans l’entreprise à toutes les étapes de la procédure pénale.\n-Auditer le risque pénal dans l’entreprise."
    },
    {
      title: 'DROIT DE LA PROPRIÉTÉ INTELLECTUELLE',
      realTitle:'DROIT DE LA PROPRIETE INTELLECTUELLE',
      imageBig: piBig,
      description: "Le cabinet intervient dans plusieurs domaines liés à la protection et à la gestion des droits de la propriété intellectuelle notamment:\n\n- Conseil sur les questions juridiques liées à la propriété intellectuelle et industrielle, y compris les droits d’auteur, les marques de commerce, les brevets, les dessins et modèles industriels, et les secrets commerciaux.\n- Conseils sur la manière de protéger et de valoriser ces droits.\n- Enregistrement et Dépôt.\n- Litiges en Propriété Intellectuelle :\n- Négociation et Rédaction de Contrats"
    },
    {
      title: 'DROIT DU NUMERIQUE ET DES TECHNOLOGIES DE L\'INFORMATION',
      realTitle:'DROIT DES DONNEES PERSONNELLES ET DES NOUVELLES TECHNOLOGIES',
      imageBig: itBig,
      description: "Nous conseillons régulièrement nos clients dans le cadre de la mise en conformé de leurs traitements au dispositions de la législation nationale sur les données personnelles.\n\n- Assistance à la conformité à la protection des données personnelles.\n- Conseil et suivi de l’activité digitale de l’entreprise.\n- Commerce éléctronique.\n- Contrats informatiques."
    },
    {
      title: 'DROIT COMMERCIAL ET DES CONTRATS',
      realTitle:'DROIT COMMERCIAL ET DES CONTRATS',
      imageBig: commerceBig,
      description: "Le cabinet Bhar dispose d’une expertise solide en droit des contrats, ses avocats interviennent notamment dans:\n\n- Négociation et rédaction des contrats commerciaux.\n- Contrats atypiques\n- Baux commerciaux.\n- Fonds de commerce.\n- Conflits contractuels.\n- Résponsabilités.\n- Arbitrage."
    },
    {
      title: 'DROIT IMMOBILIER',
      realTitle:'DROIT IMMOBILIER',
      imageBig: immoBig,
      description: "L’avocat en droit Tunisien est un rédacteur d’acte d’aliénation des droits immobiliers. Le cabinet a bâti une très forte notoriété depuis sa création en droit Immobilier. Nous intervenons régulièrement dans les opérations suivantes:\n\n- Audit Juridique immobilière et assistance conformité réglementaire.\n- Rédaction des avants contrats et actes d’aliénation des droits immobilières.\n- Partage\n- Succession et planification immobilière\n- Immatriculation foncière.\n- Mise à jour des registres fonciers.\n- Contentieux immobiliers."
    },
    {
      title: 'DROIT FISCAL',
      realTitle:'DROIT FISCAL',
      imageBig: fiscalBig,
      description: "Le cabinet entretient un partenariat avec des experts fiscaux pour vous.\nAccompagner les entreprises dans tous les domaines de la fiscalité et notamment:\n\n- Audit et conformité fiscal.\n- Structuration fiscale d’opérations portant sur les actifs immobiliers et mobilières.\n- Assistance dans un contrôle fiscal\n- Contentieux fiscal."
    }
    
  ];

  return (
    <div className="compétences">
      <div className="banner">
        <p>Nos Compétences</p>
      </div>
      <Categories
          leftButtonImage={comp}
          currentPage="compétences"
          filterArticlesByCategory={scrollToCard}
      />
      <div className="card-grid">
        {cards.map((card, index) => (
          <div key={index} id={card.realTitle}>
            <Card {...card} index={index} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Specialities;