import React, { useState, useEffect } from 'react';
import '../assets/css/Categories.css'

/* Big card imports */
import societe from '../assets/societe-white.png'
import travail from '../assets/travail-white.png'
import penal from '../assets/penal-white.png'
import pi from '../assets/pi-white.png'
import it from '../assets/it-white.png'
import commerce from '../assets/commercial-white.png'
import immo from '../assets/immobilier-white.png'
import fiscal from '../assets/fiscal-white.png'

const CategoryNav = ({ title, image, onClick, isSelected, backgroundColor, realTitle }) => (
    <div
        className={`category-button ${isSelected ? 'selected' : ''}`}
        onClick={onClick}
        style={{ cursor: 'pointer', background: isSelected ? backgroundColor.selected : backgroundColor.default }}
    >
        <img src={image} alt={title}></img>
        <h1>{title}</h1>
    </div>
);

const getBackgroundColorForPage = (page, isLeftButtonClicked) => {
    if (isLeftButtonClicked) {
        return {
            default: 'linear-gradient(to bottom, #1c468d, #163b79, #103066, #0a2553, #051b41)',
            selected: '#6981CF',
        };
    }
    switch (page) {
        case 'compétences':
            return {
                default: 'linear-gradient(to bottom, #1c468d, #163b79, #103066, #0a2553, #051b41)',
                selected: '#6981CF',
            };
        case 'articles':
            return {
                default: 'linear-gradient(to bottom, #1c468d, #163b79, #103066, #0a2553, #051b41)',
                selected: '#6981CF',
            };
        default:
            return {
                default: 'linear-gradient(to bottom, #1c468d, #163b79, #103066, #0a2553, #051b41)',
                selected: '#6981CF',
            };
    }
};

const Categories = ({ leftButtonImage, currentPage, filterArticlesByCategory,  scrollToCard }) => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [backgroundColors, setBackgroundColors] = useState(getBackgroundColorForPage(currentPage));
    const [isLeftButtonClicked, setIsLeftButtonClicked] = useState(false);

    const leftButtonStyle = isLeftButtonClicked ? { background: '#6981CF' } : {};   


    const handleCategoryClick = (realTitle) => {
        setSelectedCategory(realTitle);
        filterArticlesByCategory(realTitle);
        setIsLeftButtonClicked(false); // Reset the left button click state
    };

    const handleLeftButtonClick = () => {
        setSelectedCategory(null);
        filterArticlesByCategory(null); 
        setIsLeftButtonClicked(true); // Set the left button click state
    };

    useEffect(() => {
        setBackgroundColors(getBackgroundColorForPage(currentPage, isLeftButtonClicked));
    }, [currentPage, isLeftButtonClicked]);

    const categoryCard = [
        {
            title: 'DROIT DES SOCIÉTÉS',
            image: societe,
            realTitle:'DROIT DES SOCIETES',
        },
        {
            title: 'DROIT DU TRAVAIL',
            image: travail,
            realTitle:'DROIT DU TRAVAIL',
        },
        {
            title: 'DROIT PÉNAL',
            image: penal,
            realTitle:'DROIT PENAL DES AFFAIRES',
        },
        {
            title: 'DROIT DE LA PI',
            image: pi,
            realTitle:'DROIT DE LA PROPRIETE INTELLECTUELLE',
        },
        {
            title: 'IT',
            image: it,
            realTitle:'DROIT DES DONNEES PERSONNELLES ET DES NOUVELLES TECHNOLOGIES',
        },
        {
            title: 'DROIT COMMERCIAL',
            image: commerce,
            realTitle:'DROIT COMMERCIAL ET DES CONTRATS',
        },
        {
            title: 'DROIT IMMOBILIER',
            image: immo,
            realTitle:'DROIT IMMOBILIER',
        },
        {
            title: 'DROIT FISCAL',
            image: fiscal,
            realTitle:'DROIT FISCAL',
        },
    ];

    const renderLeftButtonContent = () => {

        if (currentPage === 'compétences') {
            return (
                <>
                    <img src={leftButtonImage} alt='Bouton'></img>
                    <span>Nos Compétences</span>
                </>
            );
        } else if (currentPage === 'articles') {
            return (
                <>
                    <img src={leftButtonImage} alt='Bouton'></img>
                    <span>Nos Articles</span>
                </>
            );
        }
        return (
            <>
                <img src={leftButtonImage} alt='Bouton'></img>
                <span>Default Text</span>
            </>
        );
    };

    return (
        <div className="sort-categories">
            <div
            
                style={{cursor:'pointer', ...leftButtonStyle}} 
                className="left-button"
                onClick={handleLeftButtonClick}>
                {renderLeftButtonContent()}
            </div>
            {categoryCard.map((card, index) => (
                <div key={index} id={card.Title} className={`div${index + 2}`}>
                    <CategoryNav
                        {...card}
                        isSelected={selectedCategory === card.realTitle}
                        onClick={() => {
                            handleCategoryClick(card.realTitle);
                            scrollToCard(card.realTitle); 
                            filterArticlesByCategory(card.realTitle); // Add this line to trigger filtering
                        }}
                        backgroundColor={backgroundColors}
                    />
                </div>
            ))}
        </div>
    );
};

export default Categories;
