import './assets/css/Home.css';
import couverture from "./assets/couverture.jpg"
import { useLocation } from 'react-router-dom';
import { useParams, Link, useNavigate } from 'react-router-dom';
import React, { useRef, useEffect, useState } from 'react';
import transparency from "./assets/transparence.png"
import dispo from "./assets/dispo.png"
import expert from "./assets/expert.png"
import inno from "./assets/inno.png"
import proac from "./assets/pro.png"
import abdBhar from './assets/abdBhar.png'
import abBhar from './assets/abBhar.png'
import articlesData from './articleData.json'

const Home = () => {
  // const controls = useAnimation();
  const location = useLocation();

  useEffect(() => {
    // If navigating from within the app and scrollToTop state is set
    if (location.state?.scrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [location]);
   
  const [topArticles, setTopArticles] = useState([]);

  const navigate = useNavigate()

  const articlesNav = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    // Then navigate to '/articles/top'
    navigate('/articles/top');
  };


  const navigateToProfile = (profileId) => {
    navigate(`/team#${profileId}`);
  };

  const contactNav = () => {
    const contactSection = document.querySelector('.footer');
    
    if (contactSection) {
        // Smooth scrolling with slower animation
        contactSection.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    } else {
        console.error('Contact section not found');
    }
};


useEffect(() => {
  const topSelectedArticles = articlesData.filter(article => article.isTopArticle).slice(0, 4);
  setTopArticles(topSelectedArticles);
}, []);


  return (
    <div className='homePage'>
      <div className="lawfirm-container">
        <div className="text-section">
          <h1>BHAR AVOCATS</h1>
          <p><strong>F</strong>ondé en <span className="date">1957</span> par le regretté Maître Salah Ourari, Bhar Avocats a établi au fil du temps
          sa réputation en conseil et en contentieux des affaires.</p>
            <p><strong>F</strong>ort de son expertise multidisciplinaire, le Cabinet répond à l’ensemble des problèmatiques juridiques
            des détenteurs d’actifs, des entreprises, de leurs dirigeants et cadres.</p>
            <p><strong>B</strong>har Avocats a noué un partenariat depuis de nombreuses années avec tout un ensemble de correspondants
            et de professionnels, Experts comptables, Experts en fiscalité, Experts fonciers, Experts informatiques
            et se présente ainsi comme un partenaire privilégié du développement de votre entreprise à toutes les étapes
            de son expansion.</p>

          <button onClick={contactNav}>NOUS CONTACTER</button>
        </div>
          <img className='clip-container' src={couverture}>
        </img>
      </div>
      <div className='why'>
        <h1>POURQUOI CHOISIR BHAR AVOCATS?</h1>
        <div className='qualities'>
            <div className='q'>
              <img src={expert}></img>
              <h2>MULTIDISCIPLINAIRE</h2>
              <p>Nous proposons une offre juridique globale en droit des affaires.</p>
            </div>
            <div className='q'>
              <img src={dispo}></img>
              <h2>DISPONIBILITÉ</h2>
              <p>Nous apportons des solutions à vos requêtes au moins de 24H.</p>
            </div>
            <div className='q'>
              <img src={proac}></img>
              <h2>PROACTIVITÉ</h2>
              <p>Nous anticipons les éventuels risques juridiques de vos décisions.</p>
            </div>
            <div className='q'>
              <img src={inno}></img>
              <h2>INNOVATION</h2>
              <p>Nous vous conseillons des stratégies innovantes optimisant vos actions.</p>
            </div>
          <div className='q'>
            <img src={transparency}></img>
            <h2>TRANSPARENCES</h2>
            <p>Nous valorisons la transparence dans toutes nos interactions.</p>
          </div>
        </div>
      </div>
      <div className="balance-bg">
      <div className='article-discovery-home'>
        <h1 style={{textTransform: 'uppercase'}}>Nos articles</h1>
        <div className='sepr'></div>
        <div className='topArticles'>
          {topArticles.map((article, index) => (
            <div key={index} className="articles-container">
              <a href={`/article/${article.title}`}>
                <img src={article.imags} alt={article.title} />
              </a>
              <div className='info'>
                <span style={{ color: "#6981CF", textAlign:"left", textTransform:"uppercase" }}> {article.dateCreated}</span>
              </div>
              <a href={`/article/${article.title}`}>
                <h2 className='article-tile' style={{ marginTop: "5%" }} >{article.title}</h2>
              </a>
            </div>
          ))}
        </div>
        <button onClick={articlesNav}>VOIR PLUS</button>
      </div>
      <div className='equipeH'>
        <h1>NOTRE ÉQUIPE</h1>
        <div className='membres'>
          <div className='m' onClick={() => navigateToProfile('abdellatif')}>
            <div className="blue-bg">
              <img src={abdBhar}></img>
            </div>
            <h2>MAÎTRE ABDELLATIF BHAR</h2>
            <p>Avocat inscrit au Barreau de Tunis depuis 1979.</p>
          </div>
          <div className='m' onClick={() => navigateToProfile('ahmed')}>
            <div className="blue-bg">
              <img src={abBhar}></img>
            </div>
            <h2>MAÎTRE AHMED BHAR</h2>
            <p>Avocat inscrit au Barreau de Tunis depuis 2008.</p>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Home;