import React from 'react';
import '../assets/css/Article.css';

const Sommaire = ({ subtitles }) => {
  return (
    <div className="sommaire">
      <h1>SOMMAIRE</h1>
      <ol>
        {subtitles.slice(1).map((subtitle, index) => (
          <li key={index}>
            <a href={`#subtitle${subtitle.title}`}>{subtitle.title}</a>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Sommaire;
