import React, { useState, useEffect } from 'react';
import '../assets/css/Contact.css'; // Import your other CSS file if needed
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import emailjs from '@emailjs/browser'

import formName from '../assets/name.png';
import formEmail from '../assets/email.png';
import formPhone from '../assets/phone.png';
import formMessage from '../assets/message.png';

const Contact = () => {

  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    number: '',
    topic: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    number: false,
    topic: false,
    message: false,
  });

  // Handle form submission
  const [submitCount, setSubmitCount] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [rateLimited, setRateLimited] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Form Values:", formValues);
  
    // Assuming that all validation is successful
    if (!Object.values(formErrors).some((error) => error)) {
      try {
        // Send email using emailjs
        await emailjs.send(
          'service_21on2qd',
          'template_c51c3x1',
          formValues, // This will automatically map to the template fields
          'ezi203fGcqKRPSfz9'
        );
  
        setSubmitCount((prevCount) => prevCount + 1);
        alert('Votre soumission a été effectuée avec succès.');
        // Optionally, you can clear the form or navigate the user to a thank-you page here
      } catch (error) {
        console.error('Error sending email:', error);
        alert('Votre soumission a échoué, veuillez réessayer ultérieurement.');
      }
    }
  };

  useEffect(() => {
    if (rateLimited) {
      const timer = setTimeout(() => {
        setRateLimited(false);
      }, 15 * 60 * 1000); // 15 minutes

      return () => clearTimeout(timer);
    }
  }, [rateLimited]);

    const buttonStyles = {
        backgroundColor: '#001F54',
        marginTop: '3vh',
        justifySelf: 'flex-end',
        alignSelf: 'flex-end',
        width: '14vw',
        height: '5vh',
        borderRadius: '5vw',
        fontWeight: 'bold',
        fontSize: '2vh',
    }

  return (
    <div className="contact-form">
      <form
        className="form-handler"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <div className="first-name">
          <TextField
            required
            fullWidth
            className="custom-textfield" // Apply the custom class
            id="input-with-icon-textfield"
            label="Nom"
            variant="outlined"
            onChange={(e) => setFormValues({ ...formValues, firstName: e.target.value })}
            error={formErrors.firstName}
            helperText={
              formErrors.firstName &&
              document.activeElement ===
                document.getElementById('input-with-icon-textfield')
                ? 'Veuillez saisir votre nom.'
                : null
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={formName} alt="Icône Nom" />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="last-name">
          <TextField
            required
            fullWidth
            className="custom-textfield" // Apply the custom class
            id="input-with-icon-textfield"
            label="Prénom"
            variant="outlined"
            onChange={(e) => setFormValues({ ...formValues, lastName: e.target.value })}
            error={formErrors.lastName}
            helperText={
              formErrors.lastName &&
              document.activeElement ===
                document.getElementById('input-with-icon-textfield')
                ? 'Veuillez saisir votre prénom.'
                : null
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={formName} alt="Icône Prénom" />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="email">
          <TextField
            fullWidth
            required
            className="custom-textfield" // Apply the custom class
            id="input-with-icon-textfield"
            label="E-Mail"
            variant="outlined"
            onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
            error={formErrors.email}
            helperText={
              formErrors.email &&
              document.activeElement ===
                document.getElementById('input-with-icon-textfield')
                ? 'Veuillez saisir votre E-Mail.'
                : null
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={formEmail} alt="Icône E-Mail" />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="number">
            <TextField
                fullWidth
                required
                    className="custom-textfield"
                id="input-with-icon-textfield"
                label="Numéro"
                variant="outlined"
                onChange={(e) => setFormValues({ ...formValues, number: e.target.value })}
                error={formErrors.number}
                helperText={
                    formErrors.number &&
                    document.activeElement ===
                    document.getElementById('input-with-icon-textfield')
                    ? 'Veuillez saisir votre numéro.'
                    : null
                }
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <img src={formPhone} alt="Icône Numéro" />
                    </InputAdornment>
                    ),
                }}
            />
        </div>
        <div className="subject">
            <TextField
                fullWidth
                required
                    className="custom-textfield"
                id="input-with-icon-textfield"
                label="Sujet"
                variant="outlined"
                onChange={(e) => setFormValues({ ...formValues, topic: e.target.value })}
                error={formErrors.topic}
                helperText={
                    formErrors.topic &&
                    document.activeElement ===
                    document.getElementById('input-with-icon-textfield')
                    ? 'Veuillez saisir un sujet.'
                    : null
                }
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <img src={formMessage} alt="Icône Sujet" />
                    </InputAdornment>
                    ),
                }}
            />
        </div>
        <div className="message">
            <TextField
                fullWidth
                required
                className="custom-textfield"
                id="input-with-icon-textfield"
                label="Message"
                variant="outlined"
                onChange={(e) => setFormValues({ ...formValues, message: e.target.value })}
                error={formErrors.message}
                helperText={
                    formErrors.message &&
                    document.activeElement ===
                    document.getElementById('input-with-icon-textfield')
                    ? 'Veuillez saisir un message.'
                    : null
                }
                multiline
                rows={3}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <img src={formMessage} alt="Icône Message" />
                    </InputAdornment>
                    ),
                }}
            />
        </div>
        <div className="button">
            <Button
                className="submit-button"
                style={{
                  ...buttonStyles,
                  ...(window.innerWidth <= 768 && {
                    width: '100%',
                    height: '4vh',
                    fontSize: '2.5vh',
                    borderRadius: '3vw',
                  }),
                }}
                variant="contained"
                type="submit"
                onClick={handleSubmit}
                disabled={rateLimited}
                >
                Submit
            </Button>
        </div>
      </form>
    </div>
  );
};

export default Contact;