import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './assets/css/App.css'
import Navbar from './components/Navbar';
import Article from './Article';
import Articles from './Articles';
import Specialities from './Specialities';
import Contact from './components/Contact';
import Home from './Home';
import Details from "./components/Details"
import { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './components/Footer';
import SecretLoginPage from "./SecretLoginPage"
import  Interface  from './Interface';

function ScrollToTop() {
  const { pathname } = useLocation();
  const noScrollPattern = /^\/articles\/[a-zA-Z]/;

  // Check if the current route matches the pattern
  const shouldScrollToTop = !noScrollPattern.test(pathname);
  // Check if the decoded pathname matches the pattern

  useEffect(() => {
    if (shouldScrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [pathname, shouldScrollToTop]);

  return null;
};
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // When the component mounts or the isAuthenticated state changes,
    // check for the presence of the token in localStorage.
    const token = localStorage.getItem('secretToken');
    if (token) {
      // Here you would also want to verify that the token is still valid
      setIsAuthenticated(true);
    }
  }, [isAuthenticated]); // Depend on isAuthenticated to re-check when it changes

  const securePath = 'login'; 

  const [showHome, setShowHome] = useState(false);
  const [startHomeAnimation, setStartHomeAnimation] = useState(false);

  return (
    <Router>
      <ScrollToTop></ScrollToTop>
      <Routes>
        <Route path="/contact" element={
          <>
            <Navbar  />
            <Footer/>
          </>
        } />
        <Route path="/home" element={
          <>
            <Navbar position={"sticky"}/>
            <Home></Home>
            <Footer></Footer>
          </>
        } />
        <Route path="/articles/" element={<Navigate to="/articles/all" />} />
        <Route path="/articles/:category" element={
          <>
            <Navbar position={"sticky"} />
            <Articles/>
            <Footer></Footer>

          </>
        } />
                <Route path="/" element={<Navigate to="/home" />} />


        <Route path="/article/:title" element={
          <>
            <Navbar  position={"sticky"}/>
            <Article />
            <Footer></Footer>

          </>
        } />
        <Route path="/specialities" element={
          <>
            <Navbar position={"sticky"}/>
            <Specialities />
            <Footer></Footer>

          </>
        } />
                <Route path="/team" element={
          <>
            <Navbar/>
            <Details />
            <Footer></Footer>

          </>
        } />
</Routes>
    </Router>
  );
}

export default App;
