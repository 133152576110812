import React, { useEffect } from 'react';
import abdBharCrop from '../assets/abdBhar_crop.png'
import abBharCrop from '../assets/abBhar_crop.png'
import '../assets/css/Details.css'

const TeamPage = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, []);

    return (
        <div className='details-page'>
          {/* Abdellatif Bhar Section */}
          <div id="abdellatif" className='team-section'>
            <div className='team-img'>
              <img src={abdBharCrop}/>
            </div>
            <div className='team-content'>
              <div className='team-name'>
                <h2>ABDELLATIF BHAR</h2>
              </div>
              <div className='team-text'>
                <div className='team-intro'>
                  <p>Avocat depuis 1979, il s’est forgé une solide réputation dans des contentieux de nature commerciale, civile et pénale ainsi que d’une expérience approfondie dans le domaine du droit foncier et patrimoniale. Il intervient en droit des affaires, tant en conseil qu’en contentieux.</p>
                </div>
                <div className='content-subtitle'>
                  <h3>FORMATION</h3>
                </div>
                <div className='subtitle-text'>
                    <li>Maitrise en droit, faculté de Droit et des Sciences Politiques de Tunis.</li>
                    <li>Certificat d’aptitude à la profession d’avocat.</li>
                </div>
                <div className='content-subtitle'>
                  <h3>COMPÉTENCES</h3>
                </div>
                <div className='subtitle-text'>
                  <li>Droit foncier, Droit pénal des affaires, Droit commercial, Droit civil, Droit du travail</li>
                </div>
                <div className='content-subtitle'>
                  <h3>LANGUES</h3>
                </div>
                <div className='subtitle-text'>
                  <li>Français, Anglais, Arabe</li>
                </div>
              </div>
            </div>
          </div>
    
          {/* Ahmed Bhar Section */}
          <div id="ahmed" className='team-section'>
            <div className='team-img'>
              <img src={abBharCrop}/>
            </div>
            <div className='team-content'>
            <div className='team-name'>
              <h2>AHMED BHAR</h2>
            </div>
            <div className='team-text'>
              <div className='team-intro'>
                <p>Inscrit au Barreau de Tunis depuis 2008, Ahmed Bhar intervient en contentieux des affaires dans l’intérêt des entreprises et leurs dirigeants.
                <br></br>
                <br></br>
                Il intervient également dans le domaine du conseil par l’accompagnement au développement commercial et structuel de l’activité des entreprises tant par l’analyse des risques juridiques que par la gestion des problématiques auxquels ils pourraient être confrontés.</p>
              </div>
              <div className='content-subtitle'>
                <h3>FORMATION</h3>
              </div>
              <div className='subtitle-text'>
                  <li>Maitrise en droit, faculté des sciences juridiques Politiques et Sociales, Tunis II</li>
                  <li>Master II en droit des contrats et responsabilité des professionnels</li>
              </div>
              <div className='content-subtitle'>
                <h3>PARCOURS</h3>
              </div>
              <div className='subtitle-text'>
                <li>Bhar Avocats depuis 2011, Expérience Farthouat et Associés, Paris, Expérience Benichou et Associés, Paris.</li>
              </div>
              <div className='content-subtitle'>
                <h3>COMPÉTENCES</h3>
              </div>
              <div className='subtitle-text'>
                <li>Droit foncier, Droit pénal des affaires, Droit commercial, Droit civil, Droit du travail, Droit fiscal</li>
              </div>
              <div className='content-subtitle'>
                <h3>LANGUES</h3>
              </div>
              <div className='subtitle-text'>
                <li>Arabe, Anglais, Français</li>
              </div>
            </div>
          </div>
          </div>
        </div>
      );
    };

export default TeamPage;
