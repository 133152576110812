import React from 'react';
import '../assets/css/Navbar.css';
import '../assets/css/App.css'
import ABw from "../assets/ABw.png";
import { useNavigate } from 'react-router-dom';
import { faMailBulk, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useState,useEffect } from 'react';

const Navbar = ({position}) => {
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState('Accueil'); // Initial active link

  const articlesNav = () => {
    navigate('/articles/all');
    setActiveLink('Articles');
    window.scrollTo(0, 0);
  };

  const homeNav = () => {
    navigate('/home');
    setActiveLink('Accueil');
    window.scrollTo(0, 0);
  };

  const contactNav = () => {
    const contactSection = document.querySelector('.footer');
    
    if (contactSection) {
        // Smooth scrolling with slower animation
        contactSection.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
        
        setActiveLink('Contact');
    } else {
        console.error('Contact section not found');
    }
};


  const specialitiesNav = () => {
    navigate("/specialities");
    setActiveLink('Compétences');
    window.scrollTo(0, 0);
  };
const [isBlurred, setIsBlurred] = useState(false);

useEffect(() => {
  const handleScroll = () => {
    setIsBlurred(window.scrollY > 0);
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

const navbarClasses = `navbar ${isBlurred ? 'blurred-navbar' : ''}`;
const logoClass = `logo ${isBlurred ? 'logo-small' : ''}`;

const currentPath = window.location.pathname;
const isArticlesActive = currentPath.includes('/article') || currentPath.includes('/articles');

const isHomeActive = currentPath === '/home';


  return (
    <>
            <div className='little-header'>
                <div className='contact-info'>
                <span>
                        <FontAwesomeIcon size='lg' icon={faMailBulk} className="icon" />
                        contact@bharlaw.com
                    </span>
                    <span>
                        <FontAwesomeIcon size='lg' icon={faPhone}  className="icon" />
                        +216 24 800 393
                    </span>
 
                </div>
            </div>
    <div className={navbarClasses} style={{ position: `${position}` }}>
      <div onClick={homeNav} className={logoClass}>
            <img src={ABw} alt="Logo" />
          </div>
          <div className="nav-links">
        <a
          onClick={homeNav}
          className={`nav-item ${isHomeActive ? 'active' : ''}`}
          >
          Accueil
        </a>
        <a
          onClick={specialitiesNav}
          className={`nav-item ${activeLink === 'Compétences' ? 'active' : ''}`}
        >
          Compétences
        </a>
        <a
          onClick={articlesNav}
          className={`nav-item ${isArticlesActive ? 'active' : ''}`}
          >
          Blog
        </a>
        <a
          onClick={contactNav}
          className={`nav-item ${activeLink === 'Contact' ? 'active' : ''}`}
        >
          Contact
        </a>
      </div>
      </div>
    </>
  );
}

export default Navbar;